<template>
	<div class="auth-wrapper auth-v1">
		<div class="auth-inner">
			<v-card class="auth-card">
				<!-- logo -->
				<v-card-title class="d-flex align-center justify-center py-7">
					<router-link to="/" class="d-flex align-center">
						<v-img
							:src="require('@/assets/images/logos/logo.png')"
							max-height="30px"
							max-width="30px"
							alt="logo"
							contain
							class="me-3"
						></v-img>

						<h2 class="text-2xl font-weight-semibold">TenWeeks</h2>
					</router-link>
				</v-card-title>

				<!-- title -->
				<v-card-text>
					<p class="text-2xl font-weight-semibold text--primary mb-2">Staff Login</p>
					<p class="mb-2">Welcome to TenWeeks! 👋🏻</p>
				</v-card-text>

				<!-- login form -->
				<v-card-text>
					<v-form ref="form" v-model="valid" lazy-validation>
						<label for="Email">Email</label>
						<v-text-field
							v-model="form.email"
							outlined
							placeholder="john@example.com"
							class="mb-3"
							required
							:rules="emailRules"
						></v-text-field>
						<label for="Password">Password</label>
						<v-text-field
							v-model="form.password"
							outlined
							:type="isPasswordVisible ? 'text' : 'password'"
							placeholder="············"
							:append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
							@click:append="isPasswordVisible = !isPasswordVisible"
							required
							:rules="passwordRules"
						></v-text-field>

						<!--<div class="d-flex align-center justify-space-between flex-wrap">
							 <v-checkbox label="Remember Me" hide-details class="me-3 mt-1"> </v-checkbox> -->

						<!-- forgot link -->
						<!-- <a href="javascript:void(0)" class="mt-1"> Forgot Password? </a>
						</div> -->

						<v-btn block color="primary" v-on:click="formSubmit" class="mt-3" :loading="loading" :disabled="loading">
							Login
						</v-btn>
						<v-btn block type="link" :to="{ name: 'customer_login' }" class="mt-6"> Go to customer Login </v-btn>
					</v-form>
				</v-card-text>

				<!-- create new account  -->
				<!-- <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
					<span class="me-2"> New on our platform? </span>
					<router-link :to="{ name: 'pages-register' }"> Create an account </router-link>
				</v-card-text> -->

				<!-- divider -->
				<!-- <v-card-text class="d-flex align-center mt-2">
					<v-divider></v-divider>
					<span class="mx-5">or</span>
					<v-divider></v-divider>
				</v-card-text> -->

				<!-- social links -->
				<!-- <v-card-actions class="d-flex justify-center">
					<v-btn v-for="link in socialLink" :key="link.icon" icon class="ms-1">
						<v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
							{{ link.icon }}
						</v-icon>
					</v-btn>
				</v-card-actions> -->
			</v-card>
		</div>

		<!-- background triangle shape  -->
		<img
			class="auth-mask-bg"
			height="173"
			:src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
		/>

		<!-- tree -->
		<v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

		<!-- tree  -->
		<v-img class="auth-tree-3" width="377" height="289" src="@/assets/images/misc/tree-3.png"></v-img>
	</div>
</template>

<script>
	import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
	import { ref, reactive } from '@vue/composition-api'
	import { mapGetters, mapActions } from 'vuex'
	import Util from '@/utils/axios-util.js'

	export default {
		setup() {
			const isPasswordVisible = ref(false)
			const loading = ref(false)
			const form = reactive({
				email: '',
				password: '',
			})
			const valid = ref(false)
			const emailRules = ref([v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'Please enter valid E-mail.'])
			const passwordRules = ref([
				v => !!v || 'Password is required',
				v => (v && v.length >= 8) || 'Password should contain minimum 8 characters.',
			])
			const socialLink = [
				{
					icon: mdiFacebook,
					color: '#4267b2',
					colorInDark: '#4267b2',
				},
				{
					icon: mdiTwitter,
					color: '#1da1f2',
					colorInDark: '#1da1f2',
				},
				{
					icon: mdiGithub,
					color: '#272727',
					colorInDark: '#fff',
				},
				{
					icon: mdiGoogle,
					color: '#db4437',
					colorInDark: '#db4437',
				},
			]

			return {
				isPasswordVisible,
				loading,
				form,
				emailRules,
				passwordRules,
				valid,
				socialLink,
				icons: {
					mdiEyeOutline,
					mdiEyeOffOutline,
				},
			}
		},
		computed: {
			...mapGetters(['currentUser', 'processing', 'loginError']),
		},
		methods: {
			...mapActions(['login', 'externalLogin', 'signOut', 'clearSession']),
			formSubmit() {
				var e = this

				let flag = e.$refs.form.validate()

				if (flag) {
					e.loading = true
					let obj = {
						email: e.form.email,
						password: e.form.password,
					}
					e.login(obj)
						.then(function (response) {
							let token = e.currentUser.token

							Util.Axios.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : ''

							e.$router.push({ name: 'dashboard' })
						})
						.catch(function (response) {
							console.log(response)
						})
						.finally(function () {
							e.loading = false
						})
				}
			},
		},
		created() {
			this.clearSession()
		},
	}
</script>

<style lang="scss">
	@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
